<template>
  <v-dialog
    v-model="active"
    :width="modalWidth"
    persistent
  >
    <AppCard
      ref="card"
      class="bg-tertiary"
    >
      <img
        src="assets/images/extension-warning/desktop@2x.png"
        class="full-width hidden-xs"
      />
      <AppButtonWhite
        class="button button--desktop hidden-xs"
        @click="active = false"
      >
        J’en profite
      </AppButtonWhite>

      <img
        src="assets/images/extension-warning/mobile@2x.png"
        class="full-width hidden-sm-and-up"
      />
      <AppButtonSmallWhite
        class="button button--mobile hidden-sm-and-up"
        width="180px"
        :size="buttonMobileSize"
        @click="active = false"
      >
        J’en profite
      </AppButtonSmallWhite>
    </AppCard>
  </v-dialog>
</template>

<script setup lang="ts">
const { smAndUp, width, height } = useDisplay()

const MODAL_WIDTH_DESKTOP = 1000
const MODAL_MARGINS = 48
const RATIO_MOBILE_IMG = 668 / 1308
const modalWidth = computed(() => {
  if (smAndUp.value) {
    return MODAL_WIDTH_DESKTOP
  }

  if (height.value > width.value) {
    return (height.value - MODAL_MARGINS) * RATIO_MOBILE_IMG
  } else {
    return 1000
  }
})

const buttonMobileSize = computed(() => {
  if (width.value < 350) {
    return 'small'
  } else {
    return 'default'
  }
})

// only one activation (cookie based)

import { useCookies } from 'vue3-cookies'
import { COOKIE_NAME_EXTENSION_PERIOD_WARNING } from '@/utils/constants'

const VALUE_AFTER_BEEING_CLOSED = 'closed'

const { cookies } = useCookies()
const active = ref(
  cookies.get(COOKIE_NAME_EXTENSION_PERIOD_WARNING) !==
    VALUE_AFTER_BEEING_CLOSED,
)
watch(active, (value) => {
  if (value) return

  cookies.set(
    COOKIE_NAME_EXTENSION_PERIOD_WARNING,
    VALUE_AFTER_BEEING_CLOSED,
    '1y',
  )
})
</script>

<style scoped>
.full-width {
  width: 100%;
}
.button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.button--mobile {
  bottom: 30%;
}
.button--desktop {
  bottom: 20%;
}
</style>
